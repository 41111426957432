<template>
  <div class="bg-white rounded-[8px] py-[32px] px-[24px]">
    <div class="flex gap-8 items-center">
      <button
        class="bg-[#F95031] flex items-center text-xl rounded-md text-white px-[10px] py-[5px]"
        @click="$router.back()"
      >
        <span class="k-arrow-left-2" />
      </button>
      <span class="font-semibold text-[24px] text-[#333333]">Detail Request Update</span>
    </div>
    <div>
      <div
        v-if="!$route.query.tab"
        class="head-section flex mb-[16px] mt-[32px]"
      >
        <span>Perlu Aksi</span>
        <span class="ml-auto rounded-full h-[24px] w-[24px] bg-[#F95031] text-white flex justify-center items-center">{{ listRequest.length }}</span>
      </div>
      <div
        v-if="!$route.query.tab"
        class="flex flex-column gap-[16px]"
      >
        <div
          v-for="req in listRequest"
          :key="req.uuid"
          class="p-[12px] rounded-[8px] border flex flex-column"
        >
          <div class="flex text-[#333333] items-center">
            <span
              :class="getIcon(req.type_request)"
              class="text-2xl"
            />
            <span class="font-semibold text-lg ml-[8px]">{{ typeRequest(req.type_request) }}</span>
            <span class="py-[4px] px-[8px] rounded-[8px] ml-auto status-request">Request</span>
          </div>
          <div class="flex items-end">
            <div class="flex flex-column">
              <span class="text-[#626262] ml-[8px]">Request edit: {{ DATE_NUMBER_SLASH(req.created_at) }}</span>
              <span class="text-[#626262] ml-[8px]">Approval: - </span>
            </div>
            <RouterLink
              :to="{
                name: $route.meta.routeApprovement,
                params: { uuid: req.uuid },
              }"
              class="ml-auto border font-semibold rounded h-fit p-[5px] text-[#333333]"
            >
              Lihat Detail <span class=" ml-1 k-arrow-right-2" />
            </RouterLink>
          </div>
        </div>
        <div
          v-if="listRequest.length === 0"
          class="flex justify-center items-center text-muted p-4"
        >
          Tidak ada yang perlu di aksi
        </div>
      </div>
      <div class="head-section flex w-full my-[16px]">
        Riwayat Approvement
        <span class="ml-auto">{{ listHistory.length }}</span>
      </div>
      <div class="flex flex-column gap-[16px]">
        <div
          v-for="req in listHistory"
          :key="req.uuid"
          class="p-[12px] rounded-[8px] border flex flex-column"
        >
          <div class="flex text-[#333333] items-center">
            <span
              :class="getIcon(req.type_request)"
              class="text-2xl"
            />
            <span
              class="font-semibold text-lg ml-[8px]"
            >{{ typeRequest(req.type_request) }}</span>
            <span
              class="py-[4px] px-[8px] rounded-[8px] ml-auto"
              :class="classRequest(req.status)"
            >{{ statusRequest(req.status) }}</span>
          </div>
          <div class="flex items-end">
            <div class="flex flex-column">
              <span class="text-[#626262] ml-[8px]">Request edit: {{ DATE_NUMBER_SLASH(req.created_at) }}</span>
              <span class="text-[#626262] ml-[8px]">Approval: {{ DATE_NUMBER_SLASH(req.updated_at) }} </span>
            </div>
            <RouterLink
              :to="{
                name: $route.meta.routeApprovement,
                params: { uuid: req.uuid },
              }"
              class="ml-auto border font-semibold rounded h-fit p-[5px] text-[#333333]"
            >
              Lihat Detail <span class=" ml-1 k-arrow-right-2" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div></template>

<script>
import { DATE_NUMBER_SLASH } from '@/libs/filterDate'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError } from '@toast'

export default {
  data() {
    return {
      DATE_NUMBER_SLASH,
      alertError,
      loading: false,
      listRequest: [],
      listHistory: [],
    }
  },
  mounted() {
    this.getListRequests()
  },
  methods: {
    async getListRequests() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}`
      const url = `v1/talent_update/${this.$route.params.id}/detail?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listRequest = data.filter(e => e.status === 'request')
          this.listHistory = data.filter(e => e.status !== 'request')

          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    getIcon(type) {
      switch (type) {
        case 'basic_information': return 'k-profile-circle'
        case 'work_history': return 'k-brifecase-timer'
        case 'bank_account': return 'k-bank'
        case 'skill': return 'k-medal'
        case 'portfolio': return 'k-archive-book'
        case 'education_work_experience': return 'k-briefcase'
        default: return '-'
      }
    },
    typeRequest(type) {
      switch (type) {
        case 'basic_information': return 'Informasi Dasar'
        case 'work_history': return 'Riwayat Kerja Talent'
        case 'bank_account': return 'Akun Bank'
        case 'skill': return 'Keahlian'
        case 'portfolio': return 'Portofolio'
        case 'education_work_experience': return 'Pendidikan & Pengalaman Kerja'
        default: return '-'
      }
    },
    classRequest(status) {
      switch (status) {
        case 'cancel': return 'status-reject'
        case 'accept': return 'status-approve'
        default: return 'status-request'
      }
    },
    statusRequest(status) {
      switch (status) {
        case 'cancel': return 'Ditolak'
        case 'accept': return 'Disetujui'
        default: return 'Request'
      }
    },
  },
}
</script>

<style scoped>
.status-request {
  background: #FCEBBE;
  color: #AF6A13;
}
.status-reject {
  background: #FFEDED;
  color: #79170A;
}
.status-approve {
  background: #DCF3EB;
  color: #10673E;
}
</style>
